

























import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

import { Tag } from '@/store/modules/tags';
import { Game } from '@/store/modules/games';

import TagComponent from "@/components/Common/Tag.vue";

@Component({
  components: {
    TagComponent,
  }
})
export default class GameTags extends Vue {
  @Prop({ type: Object, required: true } ) game: Game;

  get tags() {
    const tags = this.$store.direct.state.tags.tags;
    const gameTags = tags.filter((t1) => this.game.tags.find((t2) => t1._id === t2));
    return gameTags;
  }

  tagClicked(tag: Tag) {
    const url = `/category/${tag?.urlSegment || tag?.name.toLowerCase()}`;
    this.$router.push(url);
  }
}

