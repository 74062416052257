








































import { Component, Vue } from "vue-property-decorator";
import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class GoUltimateButton extends Vue {

  click(): void {
    StatsManager.SendButtonClickStats(location.href, "subBanner");
    const openPopup = /^true$/i.test(process.env.VUE_APP_SUBSCRIBE_BANNER_POPUP);
    if (this.$store.direct.getters.payment.enabled && this.$store.direct.getters.payment.shouldUseCGPayement)
      this.$store.direct.commit.payment.SHOW_PAY_POPUP();
    else if (openPopup) this.$store.direct.commit.mobile.SHOW_SUBSCRIBE_BANNER_POPUP();
    else this.$store.direct.dispatch.games.openSubPopup();
  }
}
