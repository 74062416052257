





















































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import ListedGame from "@/components/ListedGame.vue";
import TapBar from "@/components/TapBar.vue";
import TagScroller from "@/components/Discover/TagScroller.vue";

import { Game, SubscriptionAccess } from "@/store/modules/games";

import { Tag } from "@/store/modules/tags";
import GoUltimateButton from "@/components/Common/GoUltimateButton.vue";

@Component({
  components: {
    ListedGame,
    TapBar,
    TagScroller,
    GoUltimateButton,
  },
})
export default class GameListView extends Vue {
  loaded = false;
  tagsLoaded = false;
  allGames: Game[] = [];
  gamesFilteredByTag: Game[] = [];

  get subscribed(): boolean {
    const sub = this.$store.direct.getters.games.getHighestSubscription;
    return !sub || sub.access === SubscriptionAccess.FULL;
  }

  async loadGames(): Promise<void> {
    this.loaded = false;
    this.allGames = (await this.fetchGames()).slice().sort((a, b) => {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;
      else return 0;
    });
    this.gamesFilteredByTag = [...this.allGames];
    if (this.allGames.length > 0) this.loaded = true;
  }

  async fetchGames(): Promise<Game[]> {
    return await this.$store.direct.dispatch.games.getAllGames(true);
  }

  async fetchGamesByTag(tag: Tag): Promise<Game[]> {
    const games = await this.$store.direct.dispatch.games.getGamesByTags(tag);
    return games;
  }

  async initTags(): Promise<void> {
    await this.$store.direct.dispatch.tags.getTags();
    this.tagsLoaded = true;
  }

  updatedSelectedTag(selectedTag: Tag | undefined) {
    if (!this.loaded) {
      setTimeout(() => this.updatedSelectedTag(selectedTag), 200);
    } else {
      if (!selectedTag) {
        this.gamesFilteredByTag = this.allGames;
        return;
      }
      if (this.$route.params.tab !== (selectedTag.urlSegment || selectedTag.name.toLowerCase())) {
        this.$router.replace({ params: { ...this.$route.params, tab: selectedTag.urlSegment || selectedTag.name.toLowerCase() } });
      }
      this.fetchGamesByTag(selectedTag).then((games) => this.gamesFilteredByTag = [...games]);
    }
  }

  isFullyVisible(elem: HTMLElement): boolean {
    if (!elem?.parentElement) return false;
    const elemAbsoluteLeft = elem.offsetLeft - elem.parentElement?.scrollLeft;
    const elemAbsoluteRight = elemAbsoluteLeft + elem.offsetWidth;
    return elemAbsoluteLeft >= 0 && elemAbsoluteRight <= window.innerWidth;
  }

  async init(): Promise<void> {
    this.loadGames();

    this.$store.direct.dispatch.homeSections.getHomeSections();
  }

  async created(): Promise<void> {
    await this.initTags();
    await this.init();
  }

  async activated(): Promise<void> {
    await this.initTags();
    await this.init();
  }
}
