import * as SessionManager from "./SessionManager";

import { User } from "../models/User";
import * as Utils from "../utils/utils";

let _APIEndpoint: string | undefined = undefined;
let _user: User | undefined = undefined;
let _cgToken: string | undefined = undefined;
const _pageLoadID: string = Utils.generateId();

const _attributes: { [key: string]: unknown } = {};

/**
 * Set the API endpoint to send stats to.
 * @param endpoint The API endpoint to send stats to.
 * @throws If the API endpoint is not set.
 * @throws If the API endpoint is not a valid URL.
 * @throws If the API endpoint is not HTTPS.
 */
export function setAPIEndpoint(endpoint: string): void {
  if (!endpoint) throw new Error("API endpoint not set.");
  const url = new URL(endpoint);
  const isLocal = /(^localhost$|^127\.0\.0\.1$|^10\.|^172\.(1[6-9]|2[0-9]|31)\.|^192\.168)/.test(url.hostname);
  const isHTTPS = url.protocol === "https:";
  // API Endpoint must be HTTPS unless it's local
  if (!isLocal && !isHTTPS) throw new Error("API endpoint must be HTTPS.");
  _APIEndpoint = endpoint;
}

/**
 * Set the user to send stats for.
 * @param user The user to set.
 */
export function setUser(user: User): void {
  _user = user;
}

/**
 * Set the token to use for the Authorization header.
 * @param token The token to use for the Authorization header.
 */
export function setCGToken(token: string): void {
  _cgToken = token;
}

/**
 * Set an attribute to send with every stat.
 * @param key The key of the attribute.
 * @param value The value of the attribute.
 */
export function setAttribute(key: string, value: unknown): void {
  _attributes[key] = value;
}

/**
 * The base interface for stats.
 */
interface Stats {
  session?: SessionManager.Session;
  session_id?: string;
  log_type: string;
  date?: string;
  entrypoint_ip?: string;
  url?: string;
  sub_type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  page_load_ID?: string;
  user_agent?: string;
  language?: string;
  orientation?: string;
  user_id?: string;
  isWebApp?: boolean;
  hostname?: string;
}

interface NavigationStats extends Stats {
  log_type: "navigation_stat";
  from: string;
  to: string;
}

interface EndSessionStats extends Stats {
  log_type: "client_end_session_stat";
  reason_of_leave: string;
}

interface SessionStats extends Stats {
  log_type: "client_session_stat";
  network_score: number;
  webrtc_session_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  webrtc_stats: any;
}

interface SpeedStats extends Stats {
  speed_test_pass: "skip" | "fail" | "pass";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any;
}

/**
 * Get the current date in the format YYYY-MM-DD HH:MM:SS.SSS.
 * @returns The current date in the format YYYY-MM-DD HH:MM:SS.SSS.
 */
function GetDate(): string {
  const now = new Date();
  const dd = now.getUTCDate();
  const mm = now.getUTCMonth() + 1;
  const yyyy = now.getUTCFullYear();
  const hh = now.getUTCHours();
  const min = now.getUTCMinutes();
  const ss = now.getUTCSeconds();
  const SSS = now.getUTCMilliseconds();

  let timestamp = `${yyyy}-${mm < 10 ? `0${mm}` : mm}-${dd < 10 ? `0${dd}` : dd}`;
  timestamp = `${timestamp} ${hh < 10 ? `0${hh}` : hh}:${min < 10 ? `0${min}` : min}:${ss < 10 ? `0${ss}` : ss}.`;
  if (SSS < 10) {
    timestamp = `${timestamp}00`;
  } else if (SSS < 100) {
    timestamp = `${timestamp}0`;
  }
  timestamp = `${timestamp}${SSS}`;
  return timestamp;
}

/**
 * Enrich the stats with additional information.
 *
 * This includes the session ID, date, entrypoint IP, URL, page load ID, user agent, language, orientation, user ID, and whether the page is a web app.
 * @param stats The stats to enrich.
 * @returns The enriched stats.
 */
function enrichStats(stats: Stats): Stats {
  stats.session = SessionManager.getSession();
  stats.session_id = stats.session.id;
  stats.date = GetDate();
  stats.entrypoint_ip = _APIEndpoint;
  stats.hostname = window.location.hostname;
  stats.url = window.location.href;
  stats.page_load_ID = _pageLoadID;
  stats.user_agent = navigator.userAgent;
  stats.language = navigator.language;
  if (_user) stats.user_id = _user.id;
  if (window.screen.orientation) stats.orientation = window.screen.orientation.type;
  else stats.orientation = window.innerHeight > window.innerWidth ? "portrait" : "landscape";
  stats.isWebApp =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window.navigator as any).standalone ||
    window.matchMedia("(display-mode: standalone)").matches ||
    window.matchMedia("(display-mode: fullscreen)").matches;
  stats = { ...stats, ..._attributes };
  return stats;
}

/**
 * Send a stat to the API endpoint.
 * @param stats The stat to send.
 * @throws If the API endpoint is not set.
 */
function SendStats(stats: Stats): void {
  if (!_APIEndpoint) throw new Error("API endpoint not set.");

  const headers: Headers = new Headers();
  headers.append("Content-Type", "application/json;charset=UTF-8");
  if (_cgToken) headers.append("Authorization", `Bearer ${_cgToken}`);

  fetch(`${_APIEndpoint}/send_stat/arbitrary`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(enrichStats(stats)),
  });
}

/**
 * Send a stat to the API endpoint using an XMLHttpRequest.
 *
 * This is used for the session stats, as fetch makes the browser crash when doing too many requests.
 * @param stats The stat to send.
 */
function sendStatsXhr(stats: Stats): void {
  if (!_APIEndpoint) throw new Error("API endpoint not set.");

  const xhr = new XMLHttpRequest();
  xhr.open("POST", `${_APIEndpoint}/send_stat/arbitrary`, true);
  xhr.setRequestHeader("Content-Type", "application/json");
  if (_cgToken) xhr.setRequestHeader("Authorization", `Bearer ${_cgToken}`);
  xhr.send(JSON.stringify(enrichStats(stats)));
}

/**
 * Send arbitrary stats to the API endpoint.
 * @param log_type The log type of the stats.
 * @param data Arbitrary data to send with the stats.
 * @param additional_attributes Additional attributes to send with the stats.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SendArbitraryStats(
  log_type: string,
  data?: any,
  additional_attributes?: Record<string, unknown>
): void {
  let stats: Stats = {
    log_type: log_type,
  };
  stats = { ...stats, ...additional_attributes };
  if (data) stats.data = data;
  SendStats(stats);
}

/**
 * Send navigation stats to the API endpoint.
 * @param from The page the user navigated from.
 * @param to The page the user navigated to.
 */
export function SendNavigationStats(from: string, to: string): void {
  const stats: NavigationStats = {
    log_type: "navigation_stat",
    from,
    to,
  };
  SendStats(stats);
}

/**
 * Send end session stats to the API endpoint.
 * @param reason_of_leave The reason the user left the session.
 * @param data Arbitrary data to send with the stats.
 * @param additional_attributes Inner data to send with the stats.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SendEndSessionStats(reason_of_leave: string, data?: any, additional_attributes?: any): void {
  let stats: EndSessionStats = {
    log_type: "client_end_session_stat",
    reason_of_leave,
  };
  stats = { ...stats, ...additional_attributes };
  if (data) stats.data = data;
  SendStats(stats);
}

/**
 * Send WebRTC session stats to the API endpoint.
 *
 * The stats will be sent using an XMLHttpRequest.
 * @param webrtc_session_id The WebRTC session ID.
 * @param network_score The network score.
 * @param webrtc_stats The WebRTC stats.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SendSessionStats(webrtc_session_id: string, network_score: number, webrtc_stats: any): void {
  const stats: SessionStats = {
    log_type: "client_session_stat",
    webrtc_session_id,
    network_score,
    webrtc_stats,
  };
  sendStatsXhr(stats);
}

/**
 * Send speed test stats to the API endpoint.
 * @param speed_test_pass Whether the speed test passed.
 * @param result The result of the speed test.
 */
export function SendSpeedTestStats(speed_test_pass: "skip" | "fail" | "pass", result?: unknown): void {
  const stats: SpeedStats = {
    log_type: "client_speedtest_stat",
    speed_test_pass,
    result,
  };
  SendStats(stats);
}

/**
 * Send a button click stat to the API endpoint.
 * @param location Where the button was clicked.
 * @param button The button that was clicked.
 * @param data Arbitrary data to send with the stats.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SendButtonClickStats(location: string, button: string, data?: Record<string, unknown>): void {
  SendArbitraryStats("button_click", { location, button, ...data });
}
