






































import { Tag } from "@/store/modules/tags";
import Vue from "vue";
import { Component, Prop, } from "vue-property-decorator";

@Component
export default class TagComponent extends Vue {
  @Prop({ type: Object }) tag: Tag;
  @Prop({ type: Boolean, default: false }) isSelected: boolean;

  svgData = '';

  async mounted() {
    const response = await fetch(this.tag.icon);
    this.svgData = await response.text();
  }
}
