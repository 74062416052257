

























import Component from 'vue-class-component';
import Vue from 'vue';

import TagComponent from "@/components/Common/Tag.vue";
import { Tag } from '@/store/modules/tags';

@Component({
  components: {
    TagComponent,
  }
})
export default class TagScroller extends Vue {
  tagList: {
    tag: Tag;
    selected: boolean
  }[] = [];

  get tags(): Tag[] {
    const gs = this.$store.direct.state.tags.tags;
    return gs.filter((v) => v.enabled);
  }

  createTags() {
    this.tagList = this.tags.reduce((acc: { tag: Tag; selected: boolean }[], curr: Tag) => {
      acc.push({tag: curr, selected: false});
      return acc;
    }, []);
  }

  tagClicked(index: number) {
    this.tagList.forEach((t, i) => {
      if (i === index) {
        t.selected = !t.selected;
        if (!t.selected) this.$emit("update-selected-tag", undefined);
        else this.$emit("update-selected-tag", t.tag);
      } else {
        t.selected = false;
      }
    });
  }

  selectUrlTag() {
    const tabName = (this.$route.params.tab as string) || process.env.VUE_APP_DEFAULT_CATEGORY || "all";
    this.tagList.forEach((t) => {
      if ((t.tag.urlSegment ? t.tag.urlSegment : t.tag.name.toLowerCase()) === tabName) {
        t.selected = true;
        this.$emit("update-selected-tag", t.tag);
      }
    });
  }

  async created() {
    if (this.$store.direct.state.tags.tags.length === 0) await this.$store.direct.dispatch.tags.getTags();
    this.createTags();
    this.selectUrlTag();
  }

  async activated() {
    if (this.$store.direct.state.tags.tags.length === 0) await this.$store.direct.dispatch.tags.getTags();
    this.createTags();
    this.selectUrlTag();
  }
}

